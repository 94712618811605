// helper script for adobe analytics - download buttons tracking
(function() {
    'use scrict';

	document.querySelectorAll('[data-role="download-link"]').forEach(function(button) {
		button.addEventListener('click', function() {
			sendButton(this.href, this.getAttribute('data-download-name'));
		})
	})

	// event listener for change in actionbox
	window.addEventListener('pricebox-updated', function(e) {
		e.detail.element.querySelectorAll('[data-role="download-link"]').forEach(function(button) {
			button.addEventListener('click', function() {
				sendButton(this.href, this.getAttribute('data-download-name'));
			})
		});
	});

	function sendButton(link, id) {
		if(typeof trackCustomDownload === 'function') {
			trackCustomDownload(link, id, '');
		}
	}

})();
